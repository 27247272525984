<template>
    <el-container>
        <!-- 中间内容 -->
        <el-main>
            <h2 class="depTitle" v-if="userType=='1'">科室基准项目比价系数</h2>
            <el-table :data="
        depBenchmarktableData" border center :row-class-name="tableRowClassName">
                <el-table-column type="index" :index="indexMethod" align="center" label="序号" width="55"></el-table-column>
                <el-table-column prop="type" label="项目类型" align="center" width="100">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 1">收费项目</div>
                        <div v-else-if="scope.row.type == 0">非收费项目</div>
                    </template>
                </el-table-column>
                <el-table-column prop="projectCode" label="项目编码" width="100" align="center">
                </el-table-column>
                <el-table-column prop="priceCode" label="收费编码" align="center" width="110">
                </el-table-column>
                <el-table-column prop="name" label="项目名称" align="center">
                </el-table-column>
                <el-table-column prop="consumables" label="耗材" align="center" width="55">
                </el-table-column>
                <el-table-column prop="doctor" label="医生" align="center" width="55">
                </el-table-column>
                <el-table-column prop="nursing" label="护士" align="center" width="55">
                </el-table-column>
                <el-table-column prop="technician" label="技师" align="center" width="55">
                </el-table-column>
                <el-table-column prop="work" label="工勤" align="center" width="55">
                </el-table-column>
                <el-table-column prop="average" label="耗时(分钟)" align="center" width="60">
                </el-table-column>
                <el-table-column prop="skillClass" label="技术难度分类" align="center" width="80">
                </el-table-column>
                <el-table-column prop="skill" label="技术难度系数" align="center" width="80">
                </el-table-column>
                <el-table-column prop="riskClass" label="风险程度分类" align="center" width="80">
                </el-table-column>
                <el-table-column prop="risk" label="风险程度系数" align="center" width="80">
                </el-table-column>
                <el-table-column prop="company" label="单位" align="center" width="55">
                </el-table-column>
                <el-table-column prop="currentPrice" label="现行价格" align="center" width="60">
                </el-table-column>
                <el-table-column prop="baseClass" label="基准项目比价系数" align="center" width="90">
                </el-table-column>
            </el-table>
            <el-pagination background @current-change="handledepBenchmarkCurrentChange" :current-page="depBenchmarkpageNum" :page-sizes="[100, 200, 300, 400]" :page-size="depBenchmarkpageSize" layout=" prev, pager, next" :total="depBenchmarktotal">
            </el-pagination>
        </el-main>
    </el-container>
</template>

<script>
export default {
    props: ["keshiId"],
    data() {
        return {
            // 每页页数
            depBenchmarkpageNum: 1,
            // 每页显示条数
            depBenchmarkpageSize: 10,
            // 总页数
            depBenchmarktotal: 1,
            // 表格数据
            depBenchmarktableData: [],
            // 科室列表的id
            departId: this.keshiId,
            userType: window.sessionStorage.getItem("type"),
        };
    },
    watch: {
        keshiId: {
            handler(newName, oldName) {
                this.departId = newName;
                this.depBenchmarkpageNum=1
                this.getBusinessList();
            },
            deep: true,
        },
    },
    created() {
        // 0 医院用户   1 科室用户
        if (this.userType == "1") {
            this.departId = window.sessionStorage.getItem("departmentId");
        }
        this.getBusinessList();
    },
    methods: {
        //设置表格翻页的序号递增
        indexMethod(index) {
            let depBenchmarkpageNum = this.depBenchmarkpageNum; // 当前页码
            let depBenchmarkpageSize = this.depBenchmarkpageSize; // 每页条数
            return index + 1 + (depBenchmarkpageNum - 1) * depBenchmarkpageSize; // 返回表格序号
        },
        handledepBenchmarkCurrentChange(val) {
            // console.log(`当前页: ${val}`);
            this.depBenchmarkpageNum = val;
            this.getBusinessList();
        },
        // 表格第一行上色
        tableRowClassName({ row, rowIndex }) {
            if (row.base == "0" && rowIndex == 0) {
                return "oneBack";
            }
        },
        // 科室基准项目系数列表 分页
        async getBusinessList() {
            let data = {
                pageNum: this.depBenchmarkpageNum, //页数
                pageSize: this.depBenchmarkpageSize, //每页显示条数
                departmentId: this.departId, //科室id
            };
            let { data: res } = await this.$axios.getBusinessList(data);
            // console.log(res);
            if (res.code == 401) {
                this.$router.push("/login");
            } else if (res.code == 500) {
                this.$message({
                    message: "获取科室基准项目系数列表失败",
                    type: "error",
                });
            } else if (res.code == 200) {
                this.depBenchmarktableData = res.rows;
                this.depBenchmarktotal = res.total;
            }
        },
    },
};
</script>

<style lang="less" scoped>
.activeStatus {
    background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
    width: 25px;
    height: 25px;
    color: white;
    line-height: 25px;
    text-align: center;
    margin: 0 auto;
}

.noStatus {
    background: #ccc;
}
.depTitle {
    display: flex;
    margin: 0 auto;
    margin-bottom: 30px;
}

.depar-dia {
    width: 100%;
}

/deep/ .el-radio-button__inner {
    width: 100px;
}
.el-pagination {
    margin-top: 20px;
}

/deep/ .oneBack {
    background: #c4dfec;
}
</style>
